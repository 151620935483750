import "core-js/modules/es.array.sort.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      sortDialog: false,
      labelDialog: false,
      sortInfo: [],
      sort: {},
      sortForHttp: {
        id: null,
        sortName: "",
        sortDescription: "",
        sortType: null,
        priority: null
      },
      labelForHttp: {
        id: null,
        sortId: null,
        labelName: "",
        labelDescription: ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getSortInfo();
  },
  mounted: function mounted() {},
  methods: {
    deleteHandle: function deleteHandle(id, flag) {
      var _this = this;

      var url;

      if (flag === 1) {
        url = "/webInfo/deleteSort";
      } else if (flag === 2) {
        url = "/webInfo/deleteLabel";
      } else {
        return;
      }

      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        center: true
      }).then(function () {
        _this.$http.get(_this.$constant.baseURL + url, {
          id: id
        }, true).then(function (res) {
          _this.$message({
            message: "删除成功！",
            type: "success"
          });

          _this.getSortInfo();

          _this.sort = {};
        }).catch(function (error) {
          _this.$message({
            message: error.message,
            type: "error"
          });
        });
      }).catch(function () {
        _this.$message({
          type: 'success',
          message: '已取消删除!'
        });
      });
    },
    saveSortEdit: function saveSortEdit() {
      var _this2 = this;

      if (this.$common.isEmpty(this.sortForHttp.sortType) || this.$common.isEmpty(this.sortForHttp.priority) || this.$common.isEmpty(this.sortForHttp.sortName) || this.$common.isEmpty(this.sortForHttp.sortDescription)) {
        this.$message({
          message: "请完善所有分类信息！",
          type: "error"
        });
        return;
      }

      var url;

      if (this.$common.isEmpty(this.sortForHttp.id)) {
        url = "/webInfo/saveSort";
      } else {
        url = "/webInfo/updateSort";
      }

      this.$http.post(this.$constant.baseURL + url, this.sortForHttp, true).then(function (res) {
        _this2.$message({
          message: "保存成功！",
          type: "success"
        });

        _this2.getSortInfo();

        _this2.handleClose();
      }).catch(function (error) {
        _this2.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    saveLabelEdit: function saveLabelEdit() {
      var _this3 = this;

      if (this.$common.isEmpty(this.labelForHttp.labelName) || this.$common.isEmpty(this.labelForHttp.labelDescription)) {
        this.$message({
          message: "请完善所有标签信息！",
          type: "error"
        });
        return;
      }

      var url;

      if (this.$common.isEmpty(this.labelForHttp.id)) {
        url = "/webInfo/saveLabel";
      } else {
        url = "/webInfo/updateLabel";
      }

      this.$http.post(this.$constant.baseURL + url, this.labelForHttp, true).then(function (res) {
        _this3.$message({
          message: "保存成功！",
          type: "success"
        });

        _this3.getSortInfo();

        _this3.handleClose();

        _this3.sort = {};
      }).catch(function (error) {
        _this3.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    editSort: function editSort(sort) {
      this.sortDialog = true;
      this.sortForHttp.id = sort.id;
      this.sortForHttp.sortName = sort.sortName;
      this.sortForHttp.sortDescription = sort.sortDescription;
      this.sortForHttp.sortType = sort.sortType;
      this.sortForHttp.priority = sort.priority;
    },
    editLabel: function editLabel(label) {
      this.labelDialog = true;
      this.labelForHttp.id = label.id;
      this.labelForHttp.sortId = label.sortId;
      this.labelForHttp.labelName = label.labelName;
      this.labelForHttp.labelDescription = label.labelDescription;
    },
    insertLabel: function insertLabel(sort) {
      this.labelForHttp.sortId = sort.id;
      this.labelDialog = true;
    },
    handleClose: function handleClose() {
      this.labelForHttp = {
        id: null,
        sortId: null,
        labelName: "",
        labelDescription: ""
      };
      this.sortForHttp = {
        id: null,
        sortName: "",
        sortDescription: "",
        sortType: null,
        priority: null
      };
      this.sortDialog = false;
      this.labelDialog = false;
    },
    sayLabel: function sayLabel(sort) {
      this.sort = sort;
    },
    getSortInfo: function getSortInfo() {
      var _this4 = this;

      this.$http.get(this.$constant.baseURL + "/webInfo/getSortInfo").then(function (res) {
        if (!_this4.$common.isEmpty(res.data)) {
          _this4.sortInfo = res.data;
        }
      }).catch(function (error) {
        _this4.$message({
          message: error.message,
          type: "error"
        });
      });
    }
  }
};